<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M31.5237 6.58244C30.4104 7.07578 29.2144 7.40911 27.957 7.55978C29.2544 6.7835 30.2249 5.56173 30.6877 4.12244C29.4688 4.84643 28.1348 5.35604 26.7437 5.62911C25.8082 4.63026 24.5691 3.96821 23.2188 3.74574C21.8685 3.52327 20.4825 3.75283 19.276 4.39878C18.0695 5.04473 17.1101 6.07093 16.5466 7.31805C15.9831 8.56517 15.8471 9.96344 16.1597 11.2958C13.6899 11.1718 11.2739 10.5298 9.06826 9.41165C6.86265 8.29346 4.91681 6.72399 3.35702 4.80511C2.82369 5.72511 2.51702 6.79178 2.51702 7.92778C2.51643 8.95044 2.76827 9.95743 3.2502 10.8594C3.73212 11.7614 4.42924 12.5305 5.27969 13.0984C4.29339 13.0671 3.32885 12.8006 2.46636 12.3211V12.4011C2.46626 13.8354 2.9624 15.2256 3.8706 16.3358C4.7788 17.4459 6.04312 18.2077 7.44902 18.4918C6.53407 18.7394 5.57481 18.7759 4.64369 18.5984C5.04035 19.8326 5.81302 20.9118 6.85351 21.685C7.89401 22.4582 9.15025 22.8867 10.4464 22.9104C8.24613 24.6377 5.52887 25.5746 2.73169 25.5704C2.2362 25.5706 1.74113 25.5416 1.24902 25.4838C4.08833 27.3093 7.39348 28.2782 10.769 28.2744C22.1957 28.2744 28.4424 18.8104 28.4424 10.6024C28.4424 10.3358 28.4357 10.0664 28.4237 9.79978C29.6387 8.92108 30.6876 7.83297 31.521 6.58644L31.5237 6.58244Z" />
  </svg>
</template>
